import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from 'components/sidebar/Sidebar';
import { styled, Container, Box, Alert, AlertTitle, Stack, Typography, alignContent, Grid } from '@mui/material';
import { baselightTheme } from 'theme/DefaultColors';
import { ThemeProvider } from '@mui/material';
import Header from 'layouts/full/header/Header';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import FormLearn from './Stepper/FormLearn.tsx';




export default function Learn(roundedHeaderButton) {
    const theme = baselightTheme;
    const { t, i18n } = useTranslation();

    const MainWrapper = styled('div')(() => ({
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
      }));

      const PageWrapper = styled('div')(() => ({
        display: 'flex',
        flexGrow: 1,
        paddingBottom: '60px',
        flexDirection: 'column',
        zIndex: 1,
        backgroundColor: 'transparent',
      }));

      const [isSidebarOpen, setSidebarOpen] = useState(true);
      const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);

    return (
        <ThemeProvider theme={theme}>
        
        <MainWrapper
         className='mainwrapper'
         
        >
            <Sidebar isSidebarOpen={isSidebarOpen}
                 isMobileSidebarOpen={isMobileSidebarOpen}
                 onSidebarClose={() => setMobileSidebarOpen(false)} 
            />
            
            <PageWrapper
                 className="page-wrapper"
            >
             
            <Header toggleSidebar={() => setSidebarOpen(!isSidebarOpen)} toggleMobileSidebar={() => setMobileSidebarOpen(true)} />
           
            <Container sx={{
                paddingTop: "20px",
                maxWidth: '1200px',
                }}
            >
            
            
            <Box  sx={{ minHeight: 'calc(100vh - 170px)' }}>
            <Typography variant='h3' mt={2}>{t('learn.headline')}</Typography>
            <Grid container spacing={3}>
            <Grid item xs={2}> </Grid>
            <Grid item xs={8}>
            <Typography variant='body1' mt={2} mb={3}>{t('learn.subHeadline')}</Typography>
            </Grid>
            <Grid item xs={2}> </Grid>
            </Grid>
            
            <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography variant='h4' align='left' mt={1} mb={1}>{t('home.subHeadline')}</Typography> 
            </Grid>
            <Grid item xs={6}></Grid>
            </Grid>
            
            <Grid container spacing={2}>
            <Grid item xs={6}> 
            <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
            <Alert color='info' variant='outlined' >
                <AlertTitle> {t('learn.benefit')} </AlertTitle>  
            </Alert> 
            </Stack>
            </Grid>
            <Grid item xs={6}> 
            <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
            <Alert color='info' variant='outlined' >
               
                <AlertTitle> {t('learn.benefit2')} </AlertTitle>  
            </Alert> 
           </Stack>
            </Grid>
            </Grid>
            <Typography variant='h4' align='left' mt={1} mb={1}>{t('learn.path')}🕒</Typography> 
            <FormLearn />
            </Box>
            
           
          </Container>
          </PageWrapper>
          </MainWrapper>  
       
          
          </ThemeProvider>
    
    

    )
}