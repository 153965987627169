import React from 'react';
import { Box, AppBar, Toolbar, styled, Stack, IconButton, Badge, Button , Select, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { useTranslation, Trans } from 'react-i18next';

// components


const Header = (props) => {

  // const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  // const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const { t, i18n } = useTranslation();


  const AppBarStyled = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    background: theme.palette.background.paper,
    justifyContent: 'center',
    backdropFilter: 'blur(4px)',
    [theme.breakpoints.up('lg')]: {
      minHeight: '70px',
    },
  }));
  const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
    width: '100%',
    color: theme.palette.text.secondary,
  }));
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Clear the authentication token from sessionStorage
        sessionStorage.removeItem('Auth Token');
        // Redirect to the login page after logout
        window.location.href = '/login';
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };
  const lngs = {
    en: { nativeName: 'English', flag: '🇺🇸' },
    de: { nativeName: 'Deutsch', flag: '🇩🇪' },
    // Add other language entries with their respective flags
    es: { nativeName: 'Español', flag: '🇪🇸' },
    fr: { nativeName: 'Français', flag: '🇫🇷' },
    pt: { nativeName: 'Português', flag: '🇵🇹' },
  };

  return (
    <AppBarStyled position="sticky" color="default">
      <ToolbarStyled>
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={props.toggleMobileSidebar}
          sx={{
            display: {
              lg: "none",
              xs: "inline",
            },
          }}
        >
      
        </IconButton>


        <IconButton
          size="large"
          aria-label="show 11 new notifications"
          color="inherit"
          aria-controls="msgs-menu"
          aria-haspopup="true"
          sx={{
            ...(typeof anchorEl2 === 'object' && {
              color: 'primary.main',
            }),
          }}
        >
        

        </IconButton>
        <Box flexGrow={1} />
        <Stack spacing={1} direction="row" alignItems="center">
          <Button variant="contained" color="primary" onClick={handleLogout} target="_blank">
            {t('nav.logout')}
          </Button>
          <Button>
          <Select
              labelId="language-selector-label"
              id="language-selector"
              value={i18n.language}
              onChange={(e) => i18n.changeLanguage(e.target.value)}
              label="Language"
            >
              {Object.keys(lngs).map((lng) => (
                <MenuItem key={lng} value={lng}>
                  {lngs[lng].flag} {lngs[lng].nativeName}
                </MenuItem>
              ))}
            </Select>
          </Button>
         
        </Stack>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

Header.propTypes = {
  sx: PropTypes.object,
};

export default Header;
