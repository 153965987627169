import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';


export const Upgrade = () => {
    const { t, i18n } = useTranslation();
    return (
        
        <Box
            display={'flex'}
            alignItems="left"
            gap={2}
            sx={{  p: 1, bgcolor: `${'primary.light'}`, borderRadius: '8px' }}
        >
            <>
                <Box>

                    <Typography variant="body1" mb={1}>"Archetype, not stereotype"</Typography><br></br>
                    <Typography variant="body1" mb={1}>"Thoroughness, no shortcuts"</Typography><br></br>
                    <Typography variant="body1" mb={1}>"Reality, no mystery"</Typography><br></br>
                    <Typography variant="body1" mb={1}>"Respect, not disdain, the audience"</Typography><br></br>
                    <Typography variant="body1" mb={1}>"Originality, not duplication"</Typography><br></br>
                    
                </Box>
                {/* <Box mt="-35px">
                     <img alt="Remy Sharp" src={img1} width={100} />
                </Box>
                */}
            </>
        </Box>
    );
};