import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/treact/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/treact/headers/light.js";
import { SectionHeading } from "components/treact/misc/Headings";
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Privacy Policy" }) => {
  const { t } = useTranslation();
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
          <Typography variant='h3' mt={2}>{t('privacy.h1')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p1')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p2')}</Typography>
          <Typography variant='h3' mt={2}>{t('privacy.h2')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p3')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p4')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p5')}</Typography>
          <Typography variant='h3' mt={2}>{t('privacy.h3')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p6')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p7')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p8')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p9')}</Typography>
          <Typography variant='h3' mt={2}>{t('privacy.h5')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p10')}</Typography>
          <Typography variant='h3' mt={2}>{t('privacy.h6')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p11')}</Typography>
          <Typography variant='h3' mt={2}>{t('privacy.h7')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p12')}</Typography>
          <Typography variant='h3' mt={2}>{t('privacy.h8')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p13')}</Typography>
          <Typography variant='h3' mt={2}>{t('privacy.h9')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p14')}</Typography>
          <Typography variant='body1' mt={2}>{t('privacy.p15')}</Typography>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      
    </AnimationRevealPage>
  );
};
