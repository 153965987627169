import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Storyform from '../components/StoryForm';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import '../style.css';
import 'tailwindcss/lib/css/preflight.css';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Hero from 'components/treact/hero/BackgroundAsImage';
import FeatureWithSteps from 'components/treact/features/TwoColWithSteps.js';
import tw from 'twin.macro';
import macHeroScreenshotImageSrc from 'images/hero-screenshot-2.png';
import Pricing from 'components/treact/pricing/TwoPlansWithDurationSwitcher';
import Testimonials from 'components/treact/testimonials/TwoColumnWithImageAndRating';
import FAQs from 'components/treact/faqs/TwoColumnPrimaryBackground';
import CTA from 'components/treact/cta/GetStartedLight';
import MainFeature2 from "components/treact/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Blog from 'components/treact/blogs/ThreeColSimpleWithImageAndDashedBorder';
import Header from 'components/treact/headers/light';
import Features from 'components/treact/features/ThreeColWithSideImage';
import { Helmet } from 'react-helmet';




export default function Landing(roundedHeaderButton) {
    const { t, i18n } = useTranslation();
    const topRef = useRef(null);
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;
    
    return (
        <div ref={topRef}>

            <Helmet>
                <title>Job Application Story Tool</title>
                <meta name="description" content="This tool helps you get your job application story straight. Get started for free." />
                <meta name="keywords" content="react, meta tags, seo" />
                <meta name="author" content="Your Name" />
                <meta property="og:title" content="Job Application Story Tool" />
                <meta property="og:description" content="This tool helps you get your job application story straight. Get started for free." />
                
                <meta property="og:url" content="https://storylenses.app/" />
                <meta name="twitter:title" content="Job Application Story Tool" />
                <meta name="twitter:description" content="This tool helps you get your job application story straight. Get started for free" />
                
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

      
            
            <AnimationRevealPage>
                <Hero />
                <Features /> 
                <CTA />
            </AnimationRevealPage>
            
            
            
    
        </div>

    )
}