import * as React from 'react';
import Button from '@mui/material/Button';

export default function BasicButtons({title, handleAction}) {
    return (
        <Button 
        variant="contained" 
        fullWidth
        sx={{ mt: 3, mb: 2 }}
        onClick={handleAction}>{title}</Button>
    );
}