import { useState, useEffect } from 'react';
import './App.css';
import Form from './components/common/Form';
import Home from './pages/Home';
import Learn from './pages/Learn';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  unstable_HistoryRouter,
  Switch,
} from "react-router-dom";
import { app, db, auth } from './firebase-config';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navbar } from './components/Navbar';
import Storyform from './components/StoryForm';
import './style.css';
import 'tailwindcss/lib/css/preflight.css';
import AnimationRevealPage from './helpers/AnimationRevealPage';
import Hero from './components/treact/hero/TwoColumnWithInput';
import Landing from 'pages/Landing';
import Footer from 'components/treact/footers/MiniCenteredFooter'
import { updateDoc, doc } from 'firebase/firestore';
import Terms from './pages/TermsOfService';
import Privacy from './pages/PrivacyPolicy';
import ForgotPassword from './components/common/ForgotPassword';


function App() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate();


   // Add a state to track whether the user is authenticated
   const [user, setUser] = useState(null);
   const [customerHasPaid, setCustomerHasPaid] = useState(false);
   const [customer, setCustomer] = useState({
    email: '',
    hasPaid:'',
    stripeId: '',
    stripeLink: '',
  });
  


  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth Token');
  
    if (authToken) {
      // User is authenticated
      const currentPath = window.location.pathname;
  
      // If the current path is not login or register, navigate to the current path
      if (currentPath !== '/login' && currentPath !== '/register') {
        navigate(currentPath);
      } else {
        // If the current path is login or register, navigate to the home page
        navigate('/home');
      }
    } else {
      // User is not authenticated
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
  
          
        } else {
          setUser(null);
        }
      
      });
    }
  }, [navigate]);

 

  
  const handleAction = async (id) => {
    console.log('Email:', email);
    console.log('Password:', password);
    const authentication = getAuth();
  
    if (id === 1) {
      try {
        const response = await signInWithEmailAndPassword(authentication, email, password);
        sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
        setUser(response.user);
  
        // User has paid, navigate to home
        navigate('/home');
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          toast.error('Please check the Password');
        }
        if (error.code === 'auth/user-not-found') {
          toast.error('Please check the Email');
        }
      }
    }
  
    if (id === 2) {
      createUserWithEmailAndPassword(authentication, email, password)
        .then((response) => {
          sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
          navigate('/home');
        })
        .catch((error) => {
          if (error.code === 'auth/email-already-in-use') {
            toast.error('Email Already in Use');
          }
        });
    }
  
    if (id === 3) {
      // Handle password reset
      sendPasswordResetEmail(authentication, email)
        .then(() => {
          // Password reset email sent successfully
          console.log('Password reset email sent successfully');
          // You may want to redirect or show a message here
        })
        .catch((error) => {
          console.error('Error sending password reset email:', error.message);
          // Handle error, you may want to show an error message
        });
    }
  };

  useEffect(() => {
    // Initialize Heap tracking script
    window.heapReadyCb = window.heapReadyCb || [];
    window.heap = window.heap || [];
    window.heap.load = function (e, t) {
      window.heap.envId = e;
      window.heap.clientConfig = t = t || {};
      window.heap.clientConfig.shouldFetchServerConfig = !1;
      var a = document.createElement("script");
      a.type = "text/javascript";
      a.async = !0;
      a.src = "https://cdn.us.heap-api.com/config/" + e + "/heap_config.js";
      var r = document.getElementsByTagName("script")[0];
      r.parentNode.insertBefore(a, r);
      var n = ["init", "startTracking", "stopTracking", "track", "resetIdentity", "identify", "getSessionId", "getUserId", "getIdentity", "addUserProperties", "addEventProperties", "removeEventProperty", "clearEventProperties", "addAccountProperties", "addAdapter", "addTransformer", "addTransformerFn", "onReady", "addPageviewProperties", "removePageviewProperty", "clearPageviewProperties", "trackPageview"];
      var i = function (e) {
        return function () {
          var t = Array.prototype.slice.call(arguments, 0);
          window.heapReadyCb.push({ name: e, fn: function () { window.heap[e] && window.heap[e].apply(window.heap, t) } });
        }
      };
      for (var p = 0; p < n.length; p++) window.heap[n[p]] = i(n[p]);
    };
    window.heap.load("955839274");

   
  }, []);
  
  return (
    
<div className="App">
      
      <ToastContainer />
      <Routes>
        <Route path='/login' element={<Form title="Login" setEmail={setEmail} setPassword={setPassword} handleAction={() => handleAction(1)} />} />
        <Route path='/register' element={<Form title="Register" setEmail={setEmail} setPassword={setPassword} handleAction={() => handleAction(2)} />} />
        <Route path="/forgot-password" element={<Form title="PasswordReset" setEmail={setEmail} setPassword={setPassword} handleAction={() => handleAction(3)} />} />
        <Route path='/home' element={<Home />} />
        <Route path='/learn' element={<Learn />} />
        <Route path='/' element={<Landing />} />
        <Route path="register/:email" component={Form} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
      </Routes>
      <Footer />
      
    </div>
  );
}

export default App;