import * as React from 'react';
import Form from "@mui/material/Form";
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LearnForm from "./LearnForm.tsx";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';



const stepStyle = {
  boxShadow: 2,
  backgroundColor: "rgba(0,0,0,0.1)",
  padding: 2,
  "& .Mui-active": {
    "&.MuiStepIcon-root": {
      color: "warning.main",
      fontSize: "2rem",
    },
    "& .MuiStepConnector-line": {
      borderColor: "warning.main"
    }
  },
  "& .Mui-completed": {
    "&.MuiStepIcon-root": {
      color: "secondary.main",
      fontSize: "2rem",
    },
    "& .MuiStepConnector-line": {
      borderColor: "secondary.main"
    }
  }
}


export default function FormStepper() {
  const { t, i18n  } = useTranslation();
  const steps = [
    t('learnSteps.intro'), 
    t('learnSteps.framework'), 
    t('learnSteps.process'), 
    t('learnSteps.why'), 
    t('learnSteps.context'), 
    t('learnSteps.you'), 
    t('learnSteps.connect'), 
    t('learnSteps.narrative'), 
    t('learnSteps.material'), 
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep} sx={stepStyle}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            {
              index < 9 ?
                (<StepButton onClick={handleStep(index)}>
                  {label}
                </StepButton>) :
                (<StepButton
                  sx={activeStep === 6? { "& .MuiSvgIcon-root": { color: "warning.main", fontSize: "2rem" } } : allStepsCompleted() ? { "& .MuiSvgIcon-root": { color: "secondary.main", fontSize: "2rem" } } : { color: "rgba(0, 0, 0, 0.38)" }}
                  icon={<VisibilityIcon />}
                  onClick={handleStep(index)}
                >
                  {label}
                </StepButton>)
            }

          </Step>
        ))}
      </Stepper>
      {allStepsCompleted() ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Stack direction="row" sx={{ pt: 2 }}>
            <Stack sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Stack>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <LearnForm step={activeStep} />
          <Stack direction="row" sx={{ pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              {t('stepper.back')} 
            </Button>
            <Stack sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext} sx={{ mr: 1 }}>
            {t('stepper.next')}
            </Button>
            {activeStep !== steps.length &&
              (completed[activeStep] ? (
                <Typography variant="caption" sx={{ display: 'inline-block' }}>
                  Step {activeStep + 1} already completed
                </Typography>
              ) : (
                <Button onClick={handleComplete}>
                  {completedSteps() === totalSteps() - 1
                    ? 'Finish'
                    : t('stepper.complete')}
                </Button>
              ))}
          </Stack>
        </React.Fragment>
      )}
    </Stack >
  );
}