import React, { useState, useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import { PlayArrowRounded, PauseRounded } from '@mui/icons-material';

const AudioButton = ({ filePath }) => {
  const [paused, setPaused] = useState(true);
  const audioRef = useRef(null);

  const handleTogglePlayPause = () => {
    const audio = audioRef.current;

    if (audio) {
      if (paused) {
        audio.play();
      } else {
        audio.pause();
      }
      setPaused(!paused);
    }
  };

  useEffect(() => {
    // Cleanup audio element on component unmount
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  return (
    <div>
      <IconButton
        aria-label={paused ? 'play' : 'pause'}
        onClick={handleTogglePlayPause}
      >
        {paused ? (
          <PlayArrowRounded sx={{ fontSize: '3rem' }} color='primary' />
        ) : (
          <PauseRounded sx={{ fontSize: '3rem' }} color='primary' />
        )}
      </IconButton>

      {/* Audio Element */}
      <audio ref={audioRef} src={filePath} />
    </div>
  );
};

export default AudioButton;
