import React, { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import Header from 'components/treact/headers/light';
import { useLocation } from "react-router-dom";
import Alert from '@mui/material/Alert'; 
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="/home">
          StoryLenses App
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const defaultTheme = createTheme();

export default function BasicTextFields({title, setPassword, setEmail, handleAction, roundedHeaderButton }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const rawEmailFromParams = queryParams.get("email");
  const isPasswordResetRoute = location.pathname === "/forgot-password";
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const auth = getAuth();
  const [email, setEmailInternal] = useState('');

  useEffect(() => {
    setEmailInternal(decodeURIComponent(rawEmailFromParams || ''));
  }, [rawEmailFromParams]);

  const handleResetSuccess = () => {
    console.log('Handle Reset Success Called');

    // Check if email is not null or undefined before sending the password reset email
    if (email && email.trim() !== '') {
      // Send the password reset email
      sendPasswordResetEmail(auth, email)
        .then(() => {
          console.log('Password reset email sent successfully');
        })
        .catch((error) => {
          console.error('Error sending password reset email:', error.message);
        });
    } else {
      console.error('Email address is missing or null.');
    }

    // Set the state to show the password reset success alert
    setPasswordResetSuccess(true);
  };

  const handleFormAction = () => {
  
  
    console.log('Received ID in handleFormAction:', isPasswordResetRoute ? 3 : 1);
    if (isPasswordResetRoute) {
      // If it's a password reset route, call the reset success function
      handleResetSuccess();
    } else {
      // Otherwise, call the provided handleAction function
      handleAction();
    }
  };
  
    return (

    <ThemeProvider theme={defaultTheme}>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(/storyflow.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: '85%',
            backgroundPosition: 'center',
            
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {title} {t('login.title')}
            </Typography>
            
            <Box
                component="form"
                sx={{ mt: 1 }}
                noValidate
                autoComplete="off"
            >
                
                {isPasswordResetRoute ? (
            // Render only email input for password reset
            <TextField
              id="email"
              name="email"
              value={email}
              margin="normal"
              required
              fullWidth
              label={t('login.email')}
              variant="outlined"
              autoComplete="email"
              autoFocus
              onChange={(e) => {
                setEmailInternal(e.target.value);
                setEmail(e.target.value); // Add this line to update the prop value
              }}
            />
          ) : (
            // Render both email and password fields for login/register
            <>
              <TextField
                id="email"
                name="email"
                value={email}
                margin="normal"
                required
                fullWidth
                label={t('login.email')}
                variant="outlined"
                autoComplete="email"
                autoFocus
                onChange={(e) => {
                  setEmailInternal(e.target.value);
                  setEmail(e.target.value); // Add this line to update the prop value
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                type="password"
                name="password"
                label={t('login.password')}
                variant="outlined"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </>
          )}
                 <Button title={title} handleAction={handleFormAction} />
                 {passwordResetSuccess && (
            // Display password reset success alert
            <Alert severity="success" onClose={() => setPasswordResetSuccess(false)}>
              {t('login.reset')}
            </Alert>
          )}
                <Grid container>
                
                <Grid item xs>
                  <Link href="/forgot-password" variant="body2">
                    {t('login.forget')}
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/register" variant="body2">
                    {t('login.signup')}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
            
        
    );
}