import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import tw from "twin.macro";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const PrimaryActionWithHref = ({ href, children }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [showModal, setShowModal] = useState(false);
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setIsSmallScreen(true);
        } else {
          setIsSmallScreen(false);
        }
      };
  
      handleResize();
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    useEffect(() => {
      if (isSmallScreen) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    }, [isSmallScreen]);
  
    return (
      <>
      {!showModal &&
      <a href={href} className="inline-block">
        <PrimaryAction>{children}</PrimaryAction>
      </a>
        }
        {showModal && <BasicModal />}
      </>
    );
  };


export default function BasicModal({ href }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <a onClick={handleOpen} href={href} className="inline-block">
          <PrimaryAction>Get started</PrimaryAction>
        </a>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Open on a computer
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This tool is designed for screens larger than 768px width. 
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}