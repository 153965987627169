import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from 'components/sidebar/Sidebar';
import { styled, Container, Box, Alert, AlertTitle, Stack, Typography, Tooltip, alignContent, Grid, List, ListItem, ListItemText, ListItemIcon, Button } from '@mui/material';
import { baselightTheme } from 'theme/DefaultColors';
import { ThemeProvider } from '@mui/material';
import Header from 'layouts/full/header/Header';
import FormStepper from './Stepper/FormStepper.tsx';
import PsychologyIcon from '@mui/icons-material/Psychology';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AbcIcon from '@mui/icons-material/Abc';
import PinIcon from '@mui/icons-material/Pin';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import GetStarted from '../components/treact/cta/GetStarted.js';


export default function Home(roundedHeaderButton) {
    const theme = baselightTheme;
    const tooltipClasses = {
        tooltip: 'my-tooltip',
      };
      
      const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
        '& .my-tooltip': {
          backgroundColor: '#e6e6fa', // Apply the custom background color
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));
    const { t, i18n } = useTranslation();
    
    const handleLogout = () => {
        sessionStorage.removeItem('Auth Token');
        navigate('/login')
    }
    let navigate = useNavigate();
    const topElementRef = useRef();

    useEffect(() => {
        
        window.scrollTo(0, 0); 
        const authToken = sessionStorage.getItem('Auth Token');
        
        if (authToken) {
            setIsAuthenticated(true);
            navigate('/home');
            
        }

        if (!authToken) {
            navigate('/register');
           
        }
    }, [])

    const MainWrapper = styled('div')(() => ({
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
      }));

      const PageWrapper = styled('div')(() => ({
        display: 'flex',
        flexGrow: 1,
        paddingBottom: '60px',
        flexDirection: 'column',
        zIndex: 1,
        backgroundColor: 'transparent',
      }));
      

      const [isSidebarOpen, setSidebarOpen] = useState(true);
      const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
      const [isAuthenticated, setIsAuthenticated] = useState(false);
      const [hasPaid, setHasPaid] = useState(false);

      const checkPaymentStatus = async () => {
        // You may want to implement your logic to check payment status
        // and update the 'hasPaid' state accordingly
        // For now, setting it to true for illustration purposes
        setHasPaid(true);
      };

    return (
        <ThemeProvider theme={theme}>
        
        <MainWrapper
         className='mainwrapper'
         
        >
            <Sidebar isSidebarOpen={isSidebarOpen}
                 isMobileSidebarOpen={isMobileSidebarOpen}
                 onSidebarClose={() => setMobileSidebarOpen(false)} 
            />
            
            <PageWrapper
                 className="page-wrapper"
            >
             
             <Header toggleSidebar={() => setSidebarOpen(!isSidebarOpen)} toggleMobileSidebar={() => setMobileSidebarOpen(true)} />
           
            <Container sx={{
                paddingTop: "0px",
                maxWidth: '1400px',
                }}
            >
            <Box  sx={{ minHeight: 'calc(100vh - 170px)' }}>
            <Typography variant='h1' mt={2}>{t('home.mainHeadline')}</Typography>
            <Grid container spacing={1}>
            <Grid item xs={2}> </Grid>
            <Grid item xs={8}>
            <Typography variant='body1' mt={2} mb={3}>{t('home.herostory')}</Typography>
            <Typography variant='body1' mt={2} mb={3}>{t('home.herostory2')}</Typography>
            <Typography variant='body1' mt={2} mb={1}>{t('home.herostory3')}</Typography>
            </Grid>
            <Grid item xs={2}> </Grid>
            </Grid>
            
            
            <FormStepper />
            </Box>
            <GetStarted />       
          </Container>
          </PageWrapper>
          </MainWrapper>  
       
          
          </ThemeProvider>

    )
}