import React from 'react';
import { useLocation } from 'react-router';
import { Box, List } from '@mui/material';
import NavItem from './NavItem';
import NavGroup from './NavGroup/NavGroup';
import GradingIcon from '@mui/icons-material/Grading';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Upgrade } from './Updrade';

const SidebarItems = () => {
  const { pathname } = useLocation();
  const pathDirect = pathname;
  const { t } = useTranslation();

  const Menuitems = [
    {
      navlabel: true,
      subheader: t('subnav.create'),
    },
  
    {
      id: uniqueId(),
      title: t('subnav.job'),
      icon: GradingIcon,
      href: '/home',
    },
    {
      navlabel: true,
      subheader: t('subnav.knowledge'),
    },
    {
      id: uniqueId(),
      title: t('subnav.course'),
      icon: PsychologyIcon,
      href: '/learn',
    },
    {
      navlabel: true,
      subheader: "Mental Models",
    },
  ];

  return (
    <Box sx={{ px: 3 }}>
      <List sx={{ pt: 0 }} className="sidebarNav">
        {Menuitems.map((item) => {
          // {/********SubHeader**********/}
          if (item.subheader) {
            return <NavGroup item={item} key={item.subheader} />;

            // {/********If Sub Menu**********/}
            /* eslint no-else-return: "off" */
          } else {
            return (
              <NavItem item={item} key={item.id} pathDirect={pathDirect} />
            );
          }
        })}
      </List>
      <Upgrade />
    </Box>
  );
};
export default SidebarItems;
