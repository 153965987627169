import { useState, useEffect } from 'react'; 
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { app, db, auth } from '../../../firebase-config';
import { Select, MenuItem } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import useAnimatedNavToggler from 'helpers/useAnimatedNavToggler';
import logo from 'images/logo.svg';
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 inline-block
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
  mr-8
`;

export const SecondaryLink = tw(NavLink)`
  lg:mx-0
  px-4 py-2
  rounded
  border-2 border-primary-300
  text-primary-500
  hocus:bg-primary-500 hocus:text-gray-100
  focus:shadow-outline
  border-b-0
  ml-4
  [line-height:1.5]
  [box-sizing:border-box]
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center space-x-4
`;


export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
  const lngs = {
    en: { nativeName: 'English', flag: '🇺🇸' },
    de: { nativeName: 'Deutsch', flag: '🇩🇪' },
    // Add other language entries with their respective flags
    es: { nativeName: 'Español', flag: '🇪🇸' },
    pt: { nativeName: 'Português', flag: '🇵🇹' },
  };

  const { t, i18n } = useTranslation();

  const [isAuthenticated, setIsAuthenticated] = useState(false); 
  
  const checkPaymentStatus = async (user) => {
    try {
      // Make an API request to your backend to fetch the webhook data
      const response = await fetch('https://us-central1-storylenses-f213d.cloudfunctions.net/ext-firestore-stripe-payments-handleWebhookEvents', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid }),
      });
  
      if (response.ok) {
        const data = await response.json();
  
        // Check the webhook data to see if the user has paid
        const hasPaid = data.webhookData.payment_intent.status === 'succeeded';
  
        // Update the 'hasPaid' field using the 'db' instance from firebase-config.js
        await db.collection('customers').doc(user.uid).update({
          hasPaid: hasPaid,
        });
  
        return hasPaid;
      } else {
        console.error('Failed to fetch webhook data');
        return false; // Failed to fetch webhook data
      }
    } catch (error) {
      console.error('Error fetching webhook data:', error);
      return false; // Error occurred while fetching webhook data
    }
  };

  useEffect(() => {
    // Step 2: Listen for changes in authentication state
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsAuthenticated(!!user); // Update the state based on user authentication status
  
      if (user) {
        // Check payment status when the user logs in
        const hasPaid = await checkPaymentStatus(user);
        console.log('hasPaid (from API):', hasPaid);
  
        if (!hasPaid) {
          // If hasPaid is false, user is not authenticated
          setIsAuthenticated(false);
          // Display error message or redirect to a payment page
          
          // You might want to redirect to a payment page here
          // navigate('/payment');
        }
      }
    });
  
    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []);
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Clear the authentication token from sessionStorage
        sessionStorage.removeItem('Auth Token');
        // Redirect to the login page after logout
        window.location.href = '/login';
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const defaultLinks = [
    <NavLinks key={1}>
      {isAuthenticated &&<NavLink href="/home">Story Workshop</NavLink>}
      {!isAuthenticated && <NavLink href="/login" tw="lg:ml-12!">
      {t('nav.login')}
      </NavLink>}
      {!isAuthenticated && <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="/register">{t('nav.register')}</PrimaryLink>}
      {isAuthenticated && <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} onClick={handleLogout} >{t('nav.logout')}</PrimaryLink>}
      <NavLink >
            <Select
              labelId="language-selector-label"
              id="language-selector"
              value={i18n.language}
              onChange={(e) => i18n.changeLanguage(e.target.value)}
              label="Language"
            >
              {Object.keys(lngs).map((lng) => (
                <MenuItem key={lng} value={lng}>
                  {lngs[lng].flag} {lngs[lng].nativeName}
                </MenuItem>
              ))}
            </Select>
          </NavLink>
    </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" />
      StoryLenses App
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
