import React from 'react';
import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Grid, FormGroup, Button, Paper, Typography, styled, Tooltip, Checkbox, Stack, Alert, AlertTitle, List, ListItem, ListItemText, ListItemIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
// import { FormValues } from "./FormData";
import { useTranslation } from 'react-i18next';
import '../../styles/tooltip.css'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import GradingIcon from '@mui/icons-material/Grading';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { AttachEmail } from '@mui/icons-material';
import Diversity3Icon from '@mui/icons-material/Diversity3';

import Link from '@mui/material/Link';
import CropFreeIcon from '@mui/icons-material/CropFree';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AnimationIcon from '@mui/icons-material/Animation';
import MessageIcon from '@mui/icons-material/Message';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import Divider from '@mui/material/Divider';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FactoryIcon from '@mui/icons-material/Factory';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import WorkIcon from '@mui/icons-material/Work';
import PsychologyIcon from '@mui/icons-material/Psychology';
import RuleIcon from '@mui/icons-material/Rule';
import PortraitIcon from '@mui/icons-material/Portrait';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import HandymanIcon from '@mui/icons-material/Handyman';




const tooltipClasses = {
  tooltip: 'my-tooltip',
};


const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  '& .my-tooltip': {
    backgroundColor: '#e6e6fa', // Apply the custom background color
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


const paperInputsStyle = {
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { border: "1px solid", borderColor: "primary.main" },
    "&:hover": {
      "& > fieldset": { borderColor: "primary.light" }
    }
  },
  "& .MuiFormLabel-root": {
    color: "primary.dark"
  }
}

export default function StoryForm(props: { 
  step: number,
}) {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({});
    const handleChange = (event) => {
      setValues({ 
        ...values,
        [event.target.name]: event.target.value,
      });
    };

  return (
    <>
      <Paper sx={{
        ...paperInputsStyle,
        margin: { xs: 1, sm: 2 },
      }}
      >
        <form>
          <FormGroup sx={{ display: props.step === 0 ? "" : "none" }}>
       
      <Grid container spacing={2}>
        {/*}Section*/}
        <Grid item xs={7}>
          <Typography variant='h4' align='left' mt={2} >{t('learnSteps.intro')}</Typography>
          <Typography variant='body1' align='left' mt={2} >{t('learnSteps.introtext1')}</Typography> 
          <Typography variant='body1' align='left' mt={2} >{t('learnSteps.introtext2')}</Typography> 
          <Typography variant='body1' align='left' mt={2} >{t('learnSteps.introtext3')}</Typography> 
          <Typography variant='body1' align='left' mt={2} >{t('learnSteps.introtext4')}
          <Link href="https://discord.gg/Q3AsD4efFC" target="_blank" rel="noopener noreferrer">
          {t('learnSteps.linkText')}
          </Link>
          </Typography> 
          <Typography variant='body1' align='left' mt={2} >{t('learnSteps.introtextcta')}</Typography> 

        </Grid>
        <Grid item xs={5}>
          <img 
            src='/storyflow.png'
          />
              
        </Grid>
        
        {/*} Section */}
         </Grid>     
        </FormGroup> 
          <FormGroup sx={{ display: props.step === 1 ? "" : "none", m: 2 }}>
          <Grid container spacing={2}>
             {/*} Section */}
            <Grid item xs={7}>
            
              <Typography variant='h4' align='left' mt={2} >{t('learnSteps.frameworkHeadline')}</Typography>
              <Typography variant='body1' align='left' mt={2} >{t('learnSteps.framework1')}</Typography> 
              <Typography variant='h4' align='left' mt={2} >{t('learnSteps.frameworkSubHeadline2')}</Typography>
              <Typography variant='body1' align='left' mt={2} >{t('learnSteps.framework2')}</Typography> 
              <List>
                <ListItem>
                <ListItemIcon>
                  <CropFreeIcon />
                </ListItemIcon>
                  <ListItemText primary={t('learnSteps.framework2li1')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PersonSearchIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('learnSteps.framework2li2')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <AnimationIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.framework2li3')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <MessageIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.framework2li4')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <FormatShapesIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.framework2li5')} />
                </ListItem>
              </List>
              <Typography variant='h4' align='left' mt={2} >{t('learnSteps.frameworkSubHeadline3')}</Typography>
              <Typography variant='body1' align='left' mt={2} >{t('learnSteps.framework3')}</Typography> 
              <Typography variant='body1' align='left' mt={2} >{t('learnSteps.framework4')}</Typography> 
              <Typography variant='body1' align='left' mt={2} >{t('learnSteps.framework5')}</Typography> 
              <Typography variant='body1' align='left' mt={2} >{t('learnSteps.framework6')}</Typography> 
            </Grid>

            <Grid item xs={5}>
              <Typography variant='h5' align='left' mt={2} mb={2} >{t('learnSteps.frameworkGraph')}</Typography>
               <hr></hr>
               <img 
                src='/narrativeFlow.png'
              />
               <hr></hr>
            </Grid> 
            <Grid item xs={7}>
              <Typography variant='h4' align='left' mt={2} >{t('learnSteps.frameworkSubHeadline7')}</Typography>
              <Typography variant='body1' align='left' mt={2} >{t('learnSteps.framework7')}</Typography> 
              <Typography variant='body1' align='left' mt={2} >{t('learnSteps.framework8')}</Typography> 
            </Grid> 
            <Grid item xs={5}>
              <Typography variant='h5' align='left' mt={2} mb={2} >{t('learnSteps.frameworkGraph1')}</Typography>
              <hr></hr>
              <img 
                src='/storyFrame.png'
              />
               <hr></hr>
            </Grid> 

              {/*} Section */}     
          </Grid>
          </FormGroup>
          <FormGroup sx={{ display: props.step === 2 ? "" : "none", m: 2 }}>
           <Grid container spacing={2}>
            {/*} Section */}
            <Grid item xs={8}>
              <Typography variant='h4' align='left' mt={2} >{t('learnSteps.process')}</Typography>
              <Typography variant='body1' align='left' mt={2} >{t('learnSteps.processIntro')}</Typography>
              <List>
                <ListItem>
                <ListItemIcon>
                  <Filter1Icon />
                </ListItemIcon>
                  <ListItemText 
                  primary={t('learnSteps.processLi1')} 
                  secondary={
                  <React.Fragment>   
                    <Typography variant='body1' mt={1}>{t('learnSteps.processLi1p')}</Typography>              
                    <Typography variant='body1' mt={1}>{t('learnSteps.processLi1p1')}</Typography>              
                    <Typography variant='body1' mt={1}>{t('learnSteps.processLi1p2')}</Typography>              
                  </React.Fragment>
                  }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Filter2Icon />
                  </ListItemIcon>
                  <ListItemText primary={t('learnSteps.processLi2')} 
                    secondary={
                      <React.Fragment>   
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi2p1')}</Typography>              
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi2p2')}</Typography>              
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi2p3')}</Typography>              
                      </React.Fragment>
                      }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <Filter3Icon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.processLi3')}  
                    secondary={
                      <React.Fragment>   
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi3p1')}</Typography>              
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi3p2')}</Typography>              
                      </React.Fragment>
                      }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <Filter4Icon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.processLi4')} 
                    secondary={
                      <React.Fragment>   
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi4p1')}</Typography>              
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi4p2')}</Typography>              
                      </React.Fragment>
                      }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <Filter5Icon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.processLi5')} 
                    secondary={
                      <React.Fragment>   
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi5p1')}</Typography>              
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi5p2')}</Typography>              
                      </React.Fragment>
                      }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <Filter6Icon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.processLi6')} 
                    secondary={
                      <React.Fragment>   
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi6p1')}</Typography>              
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi6p2')}</Typography>              
                      </React.Fragment>
                      }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <Filter7Icon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.processLi7')} 
                    secondary={
                      <React.Fragment>   
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi7p1')}</Typography>              
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi7p2')}</Typography>              
                      </React.Fragment>
                      }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <Filter8Icon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.processLi8')} 
                    secondary={
                      <React.Fragment>   
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi8p1')}</Typography>              
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi8p2')}</Typography>              
                        <Typography variant='body1' mt={1}>{t('learnSteps.processLi8p3')}</Typography>              
                      </React.Fragment>
                      }
                  />
                </ListItem>
              </List> 
              
              
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h2' mt={8}>{t('learnSteps.process1')}</Typography>
              <Typography variant='h2' mt={4}>{t('learnSteps.process2')}</Typography>
              <Typography variant='h2' mt={4}>{t('learnSteps.process3')}</Typography>
              <Typography variant='h2' mt={4}>{t('learnSteps.process4')}</Typography>
              <Typography variant='h2' mt={4}>{t('learnSteps.process5')}</Typography>
              <Typography variant='h2' mt={4}>{t('learnSteps.process6')}</Typography>
              <Typography variant='h2' mt={4}>{t('learnSteps.process7')}</Typography>
              <Typography variant='h2' mt={4}>{t('learnSteps.process8')}</Typography>
              
            </Grid>
                {/*} Section */} 
            </Grid> 
          </FormGroup>
          <FormGroup sx={{ display: props.step === 3 ? "" : "none", m: 2 }}>
          <Grid container spacing={2}>
            {/*} Section */} 
            <Grid item xs={7}>
              <Typography variant='h2' align='left' mt={4}>{t('learnSteps.whyH1')}</Typography>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.whySubH')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.whyp1')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.whyp2')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.whyp3')}</Typography>
              <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
            <Alert color='info' variant='outlined' >
               
                <AlertTitle> {t('learnSteps.whyQuote1')} </AlertTitle>  
            </Alert> 
            <Alert color='info' variant='outlined' >
               
                <AlertTitle> {t('learnSteps.whyQuote2')} </AlertTitle>  
            </Alert> 
           </Stack>

            <Typography variant='body1' align='left' mt={2}>{t('learnSteps.whyp4')}</Typography>
           <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
            <Alert color='info' variant='outlined' >
               
                <AlertTitle> {t('learnSteps.whyQuote3')} </AlertTitle>  
            </Alert> 
           </Stack>
            <Typography variant='h5' align='left' mt={2}>{t('learnSteps.whySub2')}</Typography>
            <Typography variant='body1' align='left' mt={2}>{t('learnSteps.whyp5')}</Typography>
            <Typography variant='h5' align='left' mt={2}>{t('learnSteps.whySub3')}</Typography>
            <Typography variant='body1' align='left' mt={2}>{t('learnSteps.whyp6')}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant='h2' mt={4}>{t('learnSteps.whyGraph1')}</Typography>
              <img src='/why.png'/>
              <Typography variant='h5' mt={4}>{t('learnSteps.whyGraph2')}</Typography>
              <img src='/legopieces.png'/>
              <Typography variant='h5' mt={4}>{t('learnSteps.whyGraph3')}</Typography>
              <img src='/duck.png'/>
            </Grid>
           
              
              {/*} Section */}    
             </Grid>   
          </FormGroup>
          <FormGroup sx={{ display: props.step === 4 ? "" : "none", m: 2 }}>
            <Grid container spacing={2}>
            {/*} Section */} 
            <Grid item xs={7}>
            <Typography variant='h2' align='left' mt={4}>{t('learnSteps.contextH1')}</Typography>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.contextSubH')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.contextp1')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.contextp2')}</Typography>
              <List>
                <ListItem>
                <ListItemIcon>
                  <FilterAltIcon />
                </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi1')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ChecklistIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi2')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <ShowChartIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi3')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <JoinInnerIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi4')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <PortraitIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi5')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <AppRegistrationIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi6')} />
                </ListItem>
              </List>
              
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.contextp3')}</Typography>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.contextSubH4')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.contextp4')}</Typography>
              <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' variant='outlined' >
                  
                    <AlertTitle> {t('learnSteps.contextp4Quote')} </AlertTitle>  
                </Alert> 
              </Stack>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.contextp5')}</Typography>
              <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' color='info' variant='outlined' >
                    <AlertTitle> <strong>{t('learnSteps.contextH6')}</strong><br></br>{t('learnSteps.contextp6')}</AlertTitle>  
                </Alert> 
              </Stack>
              <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' color='info' variant='outlined' >
                    <AlertTitle> <strong>{t('learnSteps.contextH7')}</strong><br></br>{t('learnSteps.contextp7')}</AlertTitle>  
                </Alert> 
              </Stack>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.contextp8')}</Typography>
              <List>
                <ListItem>
                <ListItemIcon>
                  <TrendingUpIcon />
                </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi7')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FactoryIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi8')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <CorporateFareIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi9')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <JoinLeftIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi10')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <PersonSearchIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi11')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <WorkIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.contextLi12')} />
                </ListItem>
              </List>
              <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' color='info' variant='outlined' >
                    <AlertTitle> <strong>{t('learnSteps.contextH9')}</strong><br></br>{t('learnSteps.contextp9')}</AlertTitle>  
                </Alert> 
              </Stack>

            </Grid>
            <Grid item xs={5}>
              <Typography variant='h5' align='left' mt={6} mb={2}></Typography>
              <img src='/howcontext.png'/>
              <Divider component="div" role="presentation">
              <Typography variant='h5' align='left' mt={6} mb={2}>{t('learnSteps.contextGraph')}</Typography>
              </Divider>
              <img src='/context.png'/>
              <Divider component="div" role="presentation">
              <Typography variant='h5' align='left' mt={6} mb={2}>{t('learnSteps.contextGraph1')}</Typography>
              </Divider>
              <img src='/jobcontext.png'/>
             
            </Grid>
            
              {/*} Section */} 
          </Grid>

          </FormGroup>
          <FormGroup sx={{ display: props.step === 5 ? "" : "none", m: 2 }}>
          <Grid container spacing={2}>
            {/*} Section */} 
            <Grid item xs={7}>
              <Typography variant='h2' align='left' mt={4}>{t('learnSteps.youH1')}</Typography>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.youSubH1')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp1')}</Typography>
              <List>
                <ListItem>
                <ListItemIcon>
                  <PsychologyIcon />
                </ListItemIcon>
                  <ListItemText primary={t('learnSteps.youLi1')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <RuleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('learnSteps.youLi2')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <PortraitIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.youLi3')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <RecordVoiceOverIcon />
                    </ListItemIcon>
                  <ListItemText primary={t('learnSteps.youLi4')} />
                </ListItem>
              </List>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp2')}</Typography>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.youSubH3')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp3')}</Typography>
              <List>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp3Li1')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp3Li2')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp3Li3')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp3Li4')} />
                </ListItem>
              </List>              
            </Grid>



            <Grid item xs={5}>
              <Typography variant='h5' align='left' mt={6} mb={2}></Typography>
              <img src='/howidentity.png'/>
              <Typography variant='h5' mt={4}>{t('learnSteps.whyGraph2')}</Typography>
              <img src='/legopieces.png'/>
            </Grid>
            <Grid item xs={7}>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.youSubH4')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp4')}</Typography>
              <Stack sx={{ width: '100%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' color='info' variant='outlined' >
                    <AlertTitle> <strong>{t('learnSteps.youp4BoxH')}</strong><br></br>{t('learnSteps.youp4Boxp')}</AlertTitle>  
                </Alert> 
              </Stack>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.you4p1')}</Typography>
              <List>
                <ListItem>
                <ListItemIcon>
                <img src='/bluedot.png'/>
                </ListItemIcon>
                  <ListItemText primary={t('learnSteps.you4p1Li1')}  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                  <img src='/greendot.png'/>
                  </ListItemIcon>
                  <ListItemText primary={t('learnSteps.you4p1Li2')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                  <img src='/yellowdot.png'/>
                  </ListItemIcon>
                  <ListItemText primary={t('learnSteps.you4p1Li3')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                  <img src='/reddot.png'/>
                  </ListItemIcon>
                  <ListItemText primary={t('learnSteps.you4p1Li4')} />
                </ListItem>
              </List>   
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.you4SubH')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.you4p2')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.you4p3')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.you4p4')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.you4p5')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.you4p6')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.you4p7')}</Typography>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.you4SubH1')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.you4p8')}</Typography>
              <List>
                <ListItem>
                  <ListItemText primary={t('learnSteps.you4p8Li1')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.you4p8Li2')} />
                </ListItem>
              </List>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.you4SubH2')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.you4p9')}</Typography>
              <Stack sx={{ width: '100%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' color='info' variant='outlined' >
                    <AlertTitle> <strong>{t('learnSteps.you4BoxH9')}</strong><br></br>{t('learnSteps.you4Boxp91')}<br></br>{t('learnSteps.you4Boxp92')}</AlertTitle>  
                </Alert> 
              </Stack>
            </Grid>  
            <Grid item xs={5}> 
              <Divider component="div" role="presentation">
              <Typography variant='h5' align='left' mt={6} mb={2}>{t('learnSteps.youGraph')}</Typography>
              </Divider>
              <img src='/jung.png'/>
              
            </Grid>
            <Grid item xs={7}>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.youSubH5')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp5')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp51')}</Typography>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.you5H2')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp52Li1')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp52Li2')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp52Li3')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp53')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp54')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp55')}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Divider component="div" role="presentation">
                <Typography variant='h5' align='left' mt={6} mb={2}>{t('learnSteps.youSubH5Graph')}</Typography>
              </Divider>
              <img src='/swot.png'/>
              <Divider component="div" role="presentation">
                <Typography variant='h5' align='left' mt={6} mb={2}>{t('learnSteps.youSubH5Graph1')}</Typography>
              </Divider>
              <img src='/swotmatrix.png'/>
              <Divider component="div" role="presentation">
                <Typography variant='h5' align='left' mt={6} mb={2}>{t('learnSteps.youSubH5Graph2')}</Typography>
              </Divider>
              <img src='/swotexample.png'/>

            </Grid>
            <Grid item xs={7}>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.youSubH6')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp6')}</Typography>
              <List>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp6Li1')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp6Li2')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp6Li3')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp6Li4')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp6Li5')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp6Li6')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.youp6Li7')} />
                </ListItem>
              </List>    
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.youp61')}</Typography>
              <List>
                <ListItem>
                  <ListItemText primary={t('learnSteps.you62Li1')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.you62Li2')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.you62Li3')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.you62Li4')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.you62Li5')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.you62Li6')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.you62Li7')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.you62Li8')} />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={5}>
              <Divider component="div" role="presentation">
                <Typography variant='h5' align='left' mt={2} mb={2}>{t('learnSteps.youSubH6Graph')}</Typography>
              </Divider>
              <img src='/personalfacts.png'/>

            </Grid>
              {/*} Section */}   
          </Grid>

          </FormGroup>
          <FormGroup sx={{ display: props.step === 6 ? "" : "none", m: 2 }}>
            {/*} Section */}  
            <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.connectH')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.connectp')}</Typography>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.connectSubH1')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.connectp1')}</Typography>
              <List>
                <ListItem>
                  <ListItemText primary={t('learnSteps.connectp1Li1')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.connectp1Li2')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.connectp1Li3')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.connectp1Li4')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.connectp1Li5')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.connectp1Li6')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.connectp1Li7')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.connectp1Li8')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.connectp1Li9')} />
                </ListItem>
              </List> 
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.connectp2')}</Typography>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.connectSubH4')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.connectp4')}</Typography>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.connectSubH5')}</Typography>
              <Typography variant='body1' align='left' mt={2}>{t('learnSteps.connectp5')}</Typography>
              
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('learnSteps.connectp5y')}</TableCell>
                      <TableCell>{t('learnSteps.connectp5j')}</TableCell>
                      <TableCell>{t('learnSteps.connectp5b')}</TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    
                      <TableRow>
                        <TableCell>{t('learnSteps.connectp5Li1')}</TableCell>
                        <TableCell>{t('learnSteps.connectp5Li5')}</TableCell>
                        <TableCell>{t('learnSteps.connectp5Li7')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('learnSteps.connectp5Li2')}</TableCell>
                        <TableCell>{t('learnSteps.connectp5Li6')}</TableCell>
                        <TableCell>{t('learnSteps.connectp5Li8')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('learnSteps.connectp5Li3')}</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{t('learnSteps.connectp5Li9')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('learnSteps.connectp5Li4')}</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{t('learnSteps.connectp5Li10')}</TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
                <Typography variant='h5' align='left' mt={4}>{t('learnSteps.connectSubH6')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.connectp6')}</Typography>
                <Stack sx={{ width: '100%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' color='info' variant='outlined' >
                    <AlertTitle> <strong>{t('learnSteps.connectp6BoxH')}</strong><br></br>{t('learnSteps.connectp6BoxP')}</AlertTitle>  
                    <Button href='https://docs.google.com/spreadsheets/d/1xTheIGY1R4VqIng9NjH6wXgatx7iA6YWHbdWZVf-PkU/edit?usp=sharing' variant="outlined">{t('learnSteps.connectCTA')}</Button>
                </Alert> 
              </Stack>
              
            
            </Grid>
            <Grid item xs={5}>
              <Divider component="div" role="presentation">
                <Typography variant='h5' align='left' mt={2} mb={2}></Typography>
              </Divider>
              <img src='/connect.png'/>
              <Divider component="div" role="presentation">
                <Typography variant='h5' align='left' mt={2} mb={2}>{t('learnSteps.connectGraph')}</Typography>
              </Divider>
              <img src='/venn.png'/>
              <Divider component="div" role="presentation">
                <Typography variant='h5' align='left' mt={2} mb={2}>{t('learnSteps.connectGraph1')}</Typography>
              </Divider>
              <img src='/bingo.png'/>
              <Divider component="div" role="presentation">
                <Typography variant='h5' align='left' mt={4} mb={2}>{t('learnSteps.connectGraphHelper')}</Typography>
              </Divider>
              <img src='/helperSheet.png'/>

            </Grid>

          </Grid> {/*} Section */}   
          </FormGroup>
            <FormGroup sx={{ display: props.step === 7 ? "" : "none", m: 2 }}>
            
              <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography variant='h3' align='left' mt={2}>{t('learnSteps.narrativeH')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeSubH')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeH1')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP1')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP2')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP3')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP4')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP5')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP6')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeH2')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP7')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP8')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP9')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP10')}</Typography>
                <Stack sx={{ width: '100%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' color='info' variant='outlined' >
                    <AlertTitle> <strong>{t('learnSteps.narrativeH11')}</strong><br></br>{t('learnSteps.narrativeP11')}</AlertTitle>  
                    
                </Alert> 
              </Stack>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeH12')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP12')}</Typography>
                <List>
                <ListItem>
                  <ListItemText primary={t('learnSteps.narrativeP12Li')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.narrativeP12Li1')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t('learnSteps.narrativeP12Li2')} />
                </ListItem>
              </List>  

                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP13')}</Typography>
              <Stack sx={{ width: '100%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' color='info' variant='outlined' >
                    <AlertTitle> {t('learnSteps.narrativeP14')}</AlertTitle>  
                </Alert> 
              </Stack>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeP15')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeIntroH')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeIntroP')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeIntroP1')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeIntroP2')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeIntroP3')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeIntroP4')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeMainH')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeMainP')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeResolutionH1')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeResolutionP1')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeResolutionP2')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeResolutionP3')}</Typography>
               

                

              </Grid>
                <Grid item xs={4}>
                <Divider component="div" role="presentation">
                    <Typography variant='h5' align='left' mt={2} mb={2}></Typography>
                  </Divider>
                  <img src='/whatnarrative.png'/> 
                <Divider component="div" role="presentation">
                    <Typography variant='h5' align='left' mt={4} mb={2}></Typography>
                  </Divider>
                  <img src='/story.png'/> 
                  <Divider component="div" role="presentation">
                    <Typography variant='h5' align='left' mt={4} mb={2}></Typography>
                  </Divider>
                  <img src='/freytag.png'/>                
              </Grid>
              <Grid item xs={8}>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativePatternH')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativePatternP')}</Typography>
                <Stack sx={{ width: '100%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' color='info' variant='outlined' >
                    <AlertTitle> <strong>{t('learnSteps.narrativePatternH1Box')}</strong><br></br>{t('learnSteps.narrativePatternP1Box')}</AlertTitle>  
                </Alert> 
              </Stack>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeGenre1H')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre1P')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre1P1')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre1P2')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre1P3')}</Typography>
                <Stack sx={{ width: '100%' }} spacing={2} mb={2} mt={2} align='justify'>
                <Alert severity='info' color='info' variant='outlined' >
                    <AlertTitle>{t('learnSteps.narrativeGenre1H1Box')}</AlertTitle>  
                </Alert> 
              </Stack>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeGenre2H')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre2P')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeGenre3H')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre3P')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeGenre4H')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre4P')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeGenre5H')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre5P')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeGenre6H')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre6P')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeGenre7H')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre7P')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeGenre8H')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre8P')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeGenre9H')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre9P')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeGenre10H')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeGenre10P')}</Typography>
                <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativeToolingH')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.narrativeToolingP')}</Typography>
                <Button href='/home' variant="outlined">{t('lp.cta')}</Button>

              </Grid>
              <Grid item xs={4}>
              <Typography variant='h5' align='left' mt={2}>{t('learnSteps.narrativePatternH2')}</Typography>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>{t('learnSteps.narrativePatternH1Table')}</strong></TableCell>
                      <TableCell><strong>{t('learnSteps.narrativePatternH2Table')}</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    
                      <TableRow>
                        <TableCell>{t('learnSteps.narrativePatternP1TableC1')}</TableCell>
                        <TableCell>{t('learnSteps.narrativePatternP1TableC2')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('learnSteps.narrativePatternP1TableC3')}</TableCell>
                        <TableCell>{t('learnSteps.narrativePatternP1TableC4')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('learnSteps.narrativePatternP1TableC5')}</TableCell>
                        <TableCell>{t('learnSteps.narrativePatternP1TableC6')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t('learnSteps.narrativePatternP1TableC7')}</TableCell>
                        <TableCell>{t('learnSteps.narrativePatternP1TableC8')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>...</TableCell>
                        <TableCell>...</TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </Grid>


             </Grid>
            </FormGroup>
            <FormGroup sx={{ display: props.step === 8 ? "" : "none", m: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                <Typography variant='h3' align='left' mt={2}>{t('learnSteps.materialH')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.materialP')}</Typography>
                <Typography variant='h3' align='left' mt={4}><HandymanIcon /> {t('learnSteps.materialH1')}</Typography>
                <Typography variant='body1' align='left' mt={2}>{t('learnSteps.materialP2')}</Typography>
                
                </Grid>
                <Grid item xs={4}>
                  <img src='/whatmaterial.png'/> 
                </Grid>
                <Grid item xs={4}>
                  <ContentPasteSearchIcon /> 
                  <Typography variant="h4"  ml={1}>{t('search.headline')}</Typography>
                  <Typography variant="h6" mt={2} ml={1}>{t('search.subHeadline')}</Typography>
                  <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                    <Alert color='info' variant='outlined' >
               
                     <AlertTitle> {t('search.benefit')} </AlertTitle>  
                  </Alert> 
                  </Stack>
                
                </Grid>
                <Grid item xs={4}>
                  <GradingIcon />
                  <Typography variant="h4" ml={1}>{t('resume.headline')}</Typography>
                  <Typography variant="h6" mt={2} ml={1}>{t('resume.subHeadline')}</Typography>
                  <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                    <Alert color='info' variant='outlined' >
               
                     <AlertTitle> {t('resume.benefit')} </AlertTitle>  
                  </Alert> 
                  </Stack>

                </Grid>
                <Grid item xs={4}>
                <Divider component="div" role="presentation">
                  <Typography variant='h5' align='left'>{t('learnSteps.materialGraph')}</Typography>
                  </Divider>
                  <img src='/story.png'/> 

                </Grid>
                <Grid item xs={4}>
                <AttachEmail /> 
                  <Typography variant="h4" mt={2} ml={1}>{t('coverletter.headline')}</Typography>
                  <Typography variant="h6" mt={2} ml={1}>{t('coverletter.subHeadline')}</Typography>
                  <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                    <Alert color='info' variant='outlined' >
               
                     <AlertTitle> {t('coverletter.benefit')} </AlertTitle>  
                  </Alert> 
                  </Stack>
                  
                </Grid>
                <Grid item xs={4}>
                <Diversity3Icon /> 
                  <Typography variant="h4" mt={2} ml={1}>{t('interview.headline')}</Typography>
                  <Typography variant="h6" mt={2} ml={1}>{t('interview.subHeadline')}</Typography>
                  <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                    <Alert color='info' variant='outlined' >
               
                     <AlertTitle> {t('interview.benefit')} </AlertTitle>  
                  </Alert> 
                  <Alert color='info' variant='outlined' >
               
                     <AlertTitle> {t('interview.benefit2')} </AlertTitle>  
                  </Alert> 
                  </Stack>
                <Button href='/home' variant="outlined">{t('lp.cta')}</Button>

                </Grid>
              </Grid>        
            </FormGroup>      
        </form>
              
      </Paper>
      
    </>
  );
}

